import React, { useEffect, useState } from 'react';
import { FiPercent } from 'react-icons/fi';
import { RiVirusLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import '../../i18n';

import './index.scss';

const List = ({ isKorea, korList, mapCountries, vaccine, vaccineData }) => {
  const { t, i18n } = useTranslation();

  const [mapData, setMapData] = useState('');
  useEffect(() => {
    if (!mapCountries) return;
    mapCountries.sort((a, b) => b.cases - a.cases);
    setMapData(mapCountries);
    // console.log(mapCountries);
  }, [mapCountries]);

  const [isRate, setIsRate] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const btnList = [t('btncase'), t('btnrate')];
  const handleControl = (nth) => {
    setActiveIdx(nth);
    if (nth === 0) {
      setIsRate(false);
    }
    if (nth === 1) {
      setIsRate(true);
    }
  };

  const changeCountry = [
    ['UK', 'United Kingdom'],
    ['S. Korea', 'South Korea'],
    ['Bosnia', 'Bosnia and Herzegovina'],
    ['Macedonia', 'North Macedonia'],
    [`UAE`, `United Arab Emirates`], //?
    [`Côte d'Ivoire`, `Cote d'Ivoire`], //?
  ];

  return (
    <>
      <section className="tb-area-wrap">
        <div className="btn-control">
          {btnList.map((btn, i) => (
            <button
              key={i}
              className={activeIdx === i ? 'on' : ''}
              onClick={() => handleControl(i)}
            >
              {i === 0 && <RiVirusLine />}
              {i === 1 && <FiPercent />}
              {btn}
            </button>
          ))}
          {/* <button onClick={() => handleControl(false)}>
            cases &amp; recoverery
          </button>
          <button onClick={() => handleControl(true)}>rate</button> */}
        </div>
        <div className="tb-wrap">
          {mapData && vaccine && vaccineData && (
            <table>
              {!isRate ? (
                <colgroup>
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '80px' }} />
                  <col style={{ width: '10%' }} span="*" />
                </colgroup>
              ) : (
                <colgroup>
                  <col style={{ width: '44%' }} />
                  <col style={{ width: '26%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
              )}
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>
                    <span> {t('country')}</span>
                  </th>
                  {!isRate ? (
                    <>
                      <th>
                        <span>{t('vaccineRate')}</span>
                      </th>
                      <th>
                        <span>{t('vaccineComplete')}</span>
                      </th>
                      <th>
                        <span>{t('vaccineCount')}</span>
                      </th>
                      <th>
                        <span> {t('cases')}</span>
                      </th>
                      <th>
                        <span> {t('recover')}</span>
                      </th>
                      <th>
                        <span> {t('death')}</span>
                      </th>
                    </>
                  ) : (
                    <>
                      <th>
                        <span>
                          {t('per1m')} <br /> {t('cases')}
                        </span>
                      </th>
                      <th>
                        <span>{t('rateFatal')}</span>
                      </th>
                      <th>
                        <span>{t('rateRecover')}</span>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {mapData.map((data, i) => {
                  return (
                    <tr key={i}>
                      {/* <td>{i + 1}</td> */}
                      <td className="td-country">
                        <span className="num">{i + 1}</span>
                        {data.countryInfo.flag && (
                          <span className="flag">
                            <img src={data.countryInfo.flag} alt="" />
                          </span>
                        )}

                        <p className="cnt-name">
                          {isKorea
                            ? korList.filter(
                                (list, i) => list.name === data.country
                              )[0].krname
                              ? korList.filter(
                                  (list, i) => list.name === data.country
                                )[0].krname
                              : data.country
                            : data.country}
                        </p>
                      </td>
                      {!isRate ? (
                        <>
                          <td className="td-rate alR">
                            {vaccineData?.filter(
                              (v, i) =>
                                v[0] === data.country || v[1] === data.country
                            )[0] &&
                            Number(
                              Object.values(
                                vaccineData?.filter(
                                  (v, i) =>
                                    v[0] === data.country ||
                                    v[1] === data.country
                                )[0]
                              )[10]
                            ) > 0 ? (
                              <>
                                <span className="circle">
                                  <em
                                    style={{
                                      transform: `scale(${
                                        0.05 +
                                        Number(
                                          Object.values(
                                            vaccineData?.filter(
                                              (v, i) =>
                                                v[0] === data.country ||
                                                v[1] === data.country
                                            )[0]
                                          )[10]
                                        ) /
                                          100
                                      }) `,
                                    }}
                                  ></em>
                                </span>
                                {Number(
                                  Object.values(
                                    vaccineData?.filter(
                                      (v, i) =>
                                        v[0] === data.country ||
                                        v[1] === data.country
                                    )[0]
                                  )[10]
                                ).toFixed(1) + '%'}
                              </>
                            ) : (
                              ''
                            )}

                            {changeCountry.filter(
                              (v, i) => data.country === v[0]
                            )[0] &&
                            Number(
                              Object.values(
                                vaccineData.filter(
                                  (key, i) =>
                                    key[0] ===
                                    changeCountry.filter(
                                      (v, i) => data.country === v[0]
                                    )[0][1]
                                )
                              )[0][10]
                            ) > 0 ? (
                              <>
                                <span className="circle">
                                  <em
                                    style={{
                                      transform: `scale(${
                                        0.05 +
                                        Number(
                                          Object.values(
                                            vaccineData.filter(
                                              (key, i) =>
                                                key[0] ===
                                                changeCountry.filter(
                                                  (v, i) =>
                                                    data.country === v[0]
                                                )[0][1]
                                            )
                                          )[0][10]
                                        ) /
                                          100
                                      }) `,
                                    }}
                                  ></em>
                                </span>
                                {Number(
                                  Object.values(
                                    vaccineData.filter(
                                      (key, i) =>
                                        key[0] ===
                                        changeCountry.filter(
                                          (v, i) => data.country === v[0]
                                        )[0][1]
                                    )
                                  )[0][10]
                                ).toFixed(1) + '%'}
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="alR">
                            {vaccineData?.filter(
                              (v, i) =>
                                v[0] === data.country || v[1] === data.country
                            )[0] &&
                            Number(
                              Object.values(
                                vaccineData?.filter(
                                  (v, i) =>
                                    v[0] === data.country ||
                                    v[1] === data.country
                                )[0]
                              )[5]
                            ) > 0
                              ? Number(
                                  Object.values(
                                    vaccineData?.filter(
                                      (v, i) =>
                                        v[0] === data.country ||
                                        v[1] === data.country
                                    )[0]
                                  )[5]
                                ).toLocaleString()
                              : ''}

                            {changeCountry.filter(
                              (v, i) => data.country === v[0]
                            )[0] &&
                            Number(
                              Object.values(
                                vaccineData.filter(
                                  (key, i) =>
                                    key[0] ===
                                    changeCountry.filter(
                                      (v, i) => data.country === v[0]
                                    )[0][1]
                                )
                              )[0][5]
                            ) > 0
                              ? Number(
                                  Object.values(
                                    vaccineData.filter(
                                      (key, i) =>
                                        key[0] ===
                                        changeCountry.filter(
                                          (v, i) => data.country === v[0]
                                        )[0][1]
                                    )
                                  )[0][5]
                                ).toLocaleString()
                              : ''}
                          </td>

                          <td className="alR">
                            {vaccine
                              ?.filter((v, i) => v.country === data.country)[0]
                              ?.latest.toLocaleString()}
                          </td>
                          <td className="alR">
                            {data.cases > 0 ? data.cases.toLocaleString() : '-'}
                            {data.todayCases ? (
                              <p className="add">
                                +{data.todayCases.toLocaleString()}
                              </p>
                            ) : null}
                          </td>
                          <td className="alR">
                            {data.recovered > 0
                              ? data.recovered.toLocaleString()
                              : '-'}
                            {data.todayCases && data.todayCases > 0 ? (
                              <p className="add">
                                +{data.todayRecovered.toLocaleString()}
                              </p>
                            ) : null}
                          </td>
                          <td className="alR">
                            {data.deaths > 0
                              ? data.deaths.toLocaleString()
                              : '-'}
                            {data.todayCases && data.todayDeaths > 0 ? (
                              <p className="add">
                                +{data.todayDeaths.toLocaleString()}
                              </p>
                            ) : null}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="alR ">
                            {/* <span className="circle-ico">
                    <span
                      style={{
                        width: (data.casesPerOneMillion / 1000000) * 700 + '%',
                        height: (data.casesPerOneMillion / 1000000) * 700 + '%',
                      }}
                    ></span>
                  </span> */}
                            <span>
                              {data.casesPerOneMillion.toLocaleString()}
                            </span>
                          </td>
                          <td
                            className={classNames('alC', {
                              red: (data.deaths / data.cases) * 100 > 4,
                            })}
                          >
                            {((data.deaths / data.cases) * 100).toFixed(1)}%
                          </td>
                          <td
                            className={classNames('alC', {
                              blue: (data.recovered / data.cases) * 100 > 85,
                            })}
                          >
                            {((data.recovered / data.cases) * 100).toFixed(1)}%
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </section>
    </>
  );
};

export default List;
